<template>
  <div class="settings">
    <div style="width: 100%;margin-bottom: 300px;">
      <div class="create-area" v-if="step === 1">
        <p class="title">Portfolyon hangi kategoride?</p>
        <p class="sub-title">Hadi, başlayalım. 😎 Ekleyeceğin portfolyo hangi ana kategoriye giriyor?</p>

        <div class="main-cats-container">
          <div class="main-cats" v-for="cat in parentCategories">
            <div @click="selectCat(cat)" class="main-cats-bg" :style="cat.id === selectedCat.id ? 'color:white; background-image: url('+cat.square_png_hovered+')' : 'color:#5e6b79;background-image: url('+cat.square_png+')'">
              <p class="cat-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''">{{cat.name}}</p>
              <p class="cat-sub-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''"><span>{{cat.user_count | number_format}}</span> Freelancer</p>
              <p class="cat-sub-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''"><span>{{cat.gig_count | number_format}}</span> Aktif İş İlanı</p>
            </div>
          </div>
        </div>
      </div>
      <div class="create-area" v-if="step === 2">
        <p class="title">Biraz daha detay alalım!</p>
        <p class="sub-title">Peki, ekleyeceğin porfolyo <span>{{selectedCat.name}}</span> kategorisinin hangi alanına giriyor?</p>
        <div class="step-2-container">
          <div class="sub-cat-box-container">
            <div @click="selectSubCat(subCategory)" :class="selectedSubCat.id === subCategory.id ? 'sub-cat-box-active' : 'sub-cat-box'" v-for="(subCategory, ii) in selectedCat.subCategory">
              <span>{{subCategory.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="create-area" v-if="step === 3">
        <p class="title">Başlık ve Açıklama</p>
        <p class="sub-title">Harika! Şimdi portfolyonu oluşturmaya başlayabilirsin. 🚀</p>
        <p style="margin-top: 60px; margin-bottom: 30px; font-weight: 500; color: #2d3640; font-size: 26px">Etkileyici bir başlık bul!</p>
        <input maxlength="60" :style="title ? 'border: solid 1px #00a575; width:843px;' : 'width:843px;'" v-model="title" class="cuper-input" placeholder="Örnek: Tesla - Konsept Logo Tasarımı">
        <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
          {{title.length}} / <span style="color: #2d3640">60</span>
        </div>
        <p style="margin-top: 60px; margin-bottom: 30px; font-weight: 500; color: #2d3640; font-size: 26px">Porfolyonun detaylarını kısaca açıkla</p>
        <textarea maxlength="250" v-model="description" :style="description ? 'border: solid 1px #00a575;  width: 828px; height: 200px;' : ' width: 828px; height: 200px;'"   class="c-text-area" placeholder="Örnek Metin: Akıllı ev konsepti, günümüzde basit aparatlar satın alabileceğimiz ve kolayca elde edebileceğimiz ürünler haline geldi. Bu ürünler artık sadece “ışığı aç-kapa” olmaktan çıktı ve “uyandığımda kahvem hazır olsun” ve hatta daha fazlası olmaya başladı. Eminevim firması için çalıştığım EE - Smart Home uygulaması sayesinde güvenlik, kontrol ve konfor temalı birçok işlemin tek bir tuşla yapılabildiği bir deneyim hazırladım."></textarea>
        <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
          {{description.length}} / <span style="color: #2d3640">250</span>
        </div>
      </div>
      <div class="create-area" v-if="step === 4">
        <p class="title">Son Bir Adım Kaldı!</p>
        <p class="sub-title">Bionluk’taki tüm alıcılara neler yapabildiğini göstereceğin görsel, ses veya videonu ekle. Unutma burası senin portfolyon, burası senin imzan. ✌️</p>
        <div @click="openUploadModal(null,null)" class="upload-box">
          <button style="width: 220px; height: 54px;" class="cuper-black-button">
            <img src="https://gcdn.bionluk.com/site/cicons/ic-round-plus.svg"  onload="SVGInject(this)" class="upload-plus-icon">
            <span style="padding-left: 14px;">Dosya Ekle</span>
          </button>
          <div class="help-text">
            Ekleyebileceğin dosya tipleri: <span>JPG, PNG, .MP4, .MP3</span> <span class="emoji">📸 📽 🎙</span>
          </div>
        </div>


        <div class="items" style="margin-top: 60px; display: flex; flex-wrap: wrap; margin-left: -47px">
          <draggable v-model="files" class="gallery-photos-container" style=" display: flex; flex-wrap: wrap;" :options="{group:'portfolio'}">
            <div v-for="(file, fi) in files" class="item" style="margin-left: 47px" :key="file.uuid">
              <div v-if="file.file_type === 'audio'" class="video-sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="video-sound-icon">
              </div>
              <div v-if="file.file_type === 'video'" class="video-sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="video-sound-icon">
              </div>
              <img class="item-img" :src="file.cover.image_url_small">
              <div :class="fi === 0 ? 'bottom-cover' : 'bottom'">
                <div v-show="fi === 0" style="display: flex; align-items: center; justify-content: flex-start">
                  <img v-if="fi === 0" src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-icon">
                  <p class="bottom-text">Portfolyo Kapak Görseli</p>
                </div>
                <div v-show="fi !== 0" style="display: flex; align-items: center; justify-content: flex-start">
                  <div @click="makeCover(fi)" class="make-cover-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-icon">
                  </div>
                </div>
                <div style="display: flex;">
                  <div @click="openUploadModal(file, fi)" class="delete-icon-container"><img src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)" class="delete-icon"></div>
                  <div @click="removeUpload(fi)" class="delete-icon-container"><img src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon"></div>
                </div>
              </div>

            </div>
          </draggable>
        </div>

      </div>
    </div>
    <div class="bottom-fixed-div">
      <div class="bottom-centered">
        <div style="display: flex">
          <div style="width: 300px;">
            <p class="step-title">Adım {{step}} / 5</p>
            <p class="step-sub-title">{{stepName}}</p>
          </div>
          <div v-if="selectedCat.id" style="display: flex; height: 46px; margin-right:20px; align-items: center">
            <div  @click="goStep(1)" class="bottom-cat-img-container" :style="'background:'+selectedCat.cat_color">
              <img class="bottom-cat-img" :src="'https://gcdn.bionluk.com/site/cicons/catImg/squares/sm_'+selectedCat.id+'.png'">
            </div>
            <p @click="goStep(1)" class="bottom-cat-title">{{selectedCat.name}}</p>
          </div>
          <div @click="goStep(2)" v-if="selectedSubCat.id" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            <div style="display: flex;justify-content: center;">
              <p >{{selectedSubCat.name}}</p>
              <div v-if="showTooltip" style="position:absolute;width: 178px; height: 55px;margin-top: -120px;">
                <div class="arrow_box"><p style="padding-left: 15px; padding-right: 15px;">Geri dönerek düzenleme yapmak için burdaki adımları kullanabilirsin.</p></div>
                <div class="a-outer"><div class="a-inner">&nbsp;</div></div>
              </div>
            </div>
          </div>
          <div @click="goStep(3)" v-if="title" style=" cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            <div style="max-width:164px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">{{title}}</div>
          </div>
          <div @click="goStep(4)" v-if="files.length > 0" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
              Portfolyo Galerisi
          </div>
        </div>
        <div @click="nextStep" :class="enableButton ? 'continue-button-active' : 'continue-button'">
          {{step === 4 ? 'Bitir ve Yayınla' : 'Devam Et'}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    name: "src-pages-body-workstation-portfolio-create-v1",
    components: {
      draggable
    },
    data() {
      return {
        hideTooltip:false,
        buttonLoading:false,
        selectedCat:{},
        selectedSubCat:{},
        title:'',
        description:'',
        step:1,
        files:[],

      }
    },

    methods: {
      openUploadModal(sendFile=null, fileIndex=null){
        if(!sendFile) sendFile = {upload_id:null};
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.UPLOAD_GALERY, info: {sendFile, fileIndex}});
      },


      makeCover(index){
        console.log(this.files);
        let t = JSON.parse(JSON.stringify(this.files));
        let removef = t[index];
        t.splice(index, 1);
        t.unshift(removef);
        this.files = JSON.parse(JSON.stringify(t));
        this.rerender = true;
      },
      removeUpload(index){

        this.files.splice(index,1);
      },

      addPortfolioItem(payload){
        if(payload.calledFrom && payload.calledFrom === 'edit'){
          let fileIndex = payload.fileIndex;
          let tempFiles = this.files;
          let tempFile = payload.file;
          tempFiles[fileIndex] = tempFile;
          this.files =  JSON.parse(JSON.stringify(tempFiles));
        } else {
          this.files = this.files.concat(payload.file);
        }
      },

      goStep(step){
        if(step === 2 && this.showTooltip){
          this.hideTooltip = true
        } else {
          this.step = step;
          window.scrollTo(0,0);
        }

      },
      nextStep(){
        console.log(this.step)
        if(this.enableButton){
          if(this.step === 4){
            if(!this.enableButton) return false;
            if(this.buttonLoading) return false;

            this.buttonLoading = true;
            let postedFiles = [];
            this.files.forEach(function (file) {

              postedFiles.push({
                upload_id:file.upload_id,
                cover_id:file.cover.upload_id
              });
            });
            let updatedPortfolio = {
              name:this.title,
              description:this.description,
              category_id:this.selectedCat.id,
              category_sub_id:this.selectedSubCat.id,
              status:1,
              upload_id:this.files[0].upload_id,
              cover_id:this.files[0].cover.upload_id,
              upload_info: JSON.stringify({items:postedFiles})
            }
            this.api.seller.createPortfolioV2(updatedPortfolio, this.$Progress)
              .then(({data}) => {
                let result = data;
                this.activeProgress = null;
                if (result.success) {
                  this.$toasted.global.infoToast({description: result.message});
                  this.$router.push('/panel/portfolyo');
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                }
              })
              .catch(err => {
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
                this.buttonLoading = false;
              });
          } else {
            this.step++;
            window.scrollTo(0,0);
          }
        }
      },

      selectCat(cat){
        this.selectedSubCat = {};
        this.selectedCat = cat;
      },

      selectSubCat(subCat){
        this.selectedSubCat = subCat;
      },

    },

    computed: {
      showTooltip: function(){
        if (this.hideTooltip){
          return false
        } else {
          return this.step > 2;
        }
      },
      enableButton: function(){
        let ret = false;
        if(this.step === 1){
          if(this.selectedCat.id){
            ret = true;
          }
        }
        if(this.step === 2){
          if(this.selectedSubCat.id){
            ret = true;
          }
        }
        if(this.step === 3){
          if(this.title){
            ret = true;
          }
        }
        if(this.step === 4){
          if(this.files.length > 0){
            ret = true;
          }
        }

        if(this.buttonLoading){
          ret = false;
        }

        return ret;
      },

      stepName: function () {
        if(this.step === 1){
          return 'Kategori Seçimi';
        }
        if(this.step === 2){
          return 'Alt Kategori Seçimi';
        }
        if(this.step === 3){
          return 'Başlık ve Açıklamaları';
        }
        if(this.step === 4){
          return 'Portfolyo Galerisi';
        }
      }
    },
    created() {
      this.EventBus.$on('addPortfolioItem', payload => {
        this.addPortfolioItem(payload);
      })
    },

    beforeDestroy() {
      this.EventBus.$off("addPortfolioItem");
    }
  }
</script>

<style scoped lang="scss">

  .items{

    .item{
      position: relative;
      width: 250px;
      height: 181px;
      line-height: 0;
      margin-bottom: 30px;
      .item-img{
        cursor: move;
        width: 250px;
        height: 141px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .bottom{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        background-color: #5e6b79;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .bottom-cover{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        background-color: #fd4056;
      }

      .video-sound-icon-container{
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background-color: rgba(45, 54, 64, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top:10px;
        left:10px;
        .video-sound-icon /deep/ {
          width: 22px;
          height: 22px;
          path {
            fill: #fff;
          }
        }
      }


      .delete-icon-container{
        margin-right: 10px;
        width: 26px;
        height: 26px;
        border-radius: 5px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .delete-icon /deep/ {
          width: 16px;
          height: 16px;
          path {
            fill: #2d3640;
          }
        }
        &:hover{
          opacity: 0.8;
        }
      }

      .bottom-text{
        margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #eff3f8;
      }

      .star-icon /deep/ {
        margin-left: 15px;
        width: 20px;
        height: 20px;
        path {
          fill: #fff;
        }
      }
    }


    .make-cover-container{
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }

  }

  .upload-box{

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 62px;
    width: 843px;
    height: 172px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);

    .help-text{
      margin-top: 35px;
      font-size: 14px;
      font-weight: 600;
      color: #2d3640;

      span{
        padding-left: 5px;
        font-size: 14px;
        font-weight: 300;
        color: #8b95a1;
      }

      .emoji{
        padding-left: 20px;
        font-size: 18px;
        font-weight: normal;
        color: #2d3640;
      }
    }

    .upload-plus-icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #fff;
        &:hover{
          opacity: 0.8;
        }
      }
    }

    &:hover{
      border: dashed 2px #fd4056;
      .cuper-black-button{
        background: #fd4056;
      }
    }
  }


  .a-inner{
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color:#ffbf00;


    width: 25px;
    height: 40px;
    top: -35px;
    position:relative;
    -moz-border-radius: 3px;
    border-radius: 3px;

  }

  .a-outer {
    z-index: 999999999;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 82px;

    overflow: hidden;

  }

  .arrow_box {

    border-radius: 2px;
    background: #ffbf00;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.46;
    width: 178px;
    height: 92px;
    color: #2d3640;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-2-container{
    margin-top: 60px;
    .header-img{
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 843px;
      height: 103px;
      background-repeat: no-repeat;
      background-size: 843px;
      border-radius:10px;
    }

    .step-2-text-container{
      margin-left: 108px;
    }
    .step-2-title{
      font-size: 18px;
      font-weight: 600;

      margin-top: 5px;
    }
    .step-2-sub-title{
      font-size: 16px;
      line-height: 1.25;

      span{
        font-weight: bold;
      }
    }




  }

  .sub-cat-box-container{
    display: flex;
    flex-wrap: wrap;
    width: 843px;
    justify-content: space-between;
    .sub-cat-box{
      cursor: pointer;
      margin-bottom: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #bfc8d2;
      background-color: #ffffff;
      font-size: 18px;
      color: #8b95a1;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }
      &:hover{
        border: solid 1px #00a575;
        background-color: #ffffff;
        color: #2d3640;
      }
    }

    .sub-cat-box-active{
      margin-bottom: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #00a575;
      background-color: rgba(103, 204, 100, 0.1);
      font-size: 18px;
      color: #2d3640;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }

    }
  }


  .bottom-fixed-div {
    position:fixed; bottom:0; width: 100%; background: linear-gradient(to right, #0e0e0f, #2d3740); height: 100px; z-index:11;
    left: 0;


    .continue-button{
      position: absolute;
      right: 0;
      background: #8b95a1; height: 100px; width: 200px;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .continue-button-active{
      position: absolute;
      right: 0;
      cursor: pointer;
      -webkit-transition: all 0.18s ease-out;
      -moz-transition: all 0.18s ease-out;
      -ms-transition: all 0.18s ease-out;
      -o-transition: all 0.18s ease-out;
      transition: all 0.18s ease-out;

      height: 100px; width: 200px;
      background-color: #00a575;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        height: 120px; width: 240px;

      }
    }

    .bottom-cat-img-container{
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 46px;
      height: 46px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .bottom-cat-img{
        width: 22px;
        height: 22px;
      }
    }

    .bottom-cat-title{
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.13;
      color: #ffffff;
      margin-left: 10px;

    }

    .bottom-centered{
      margin: 0 auto;
      width: 1440px;
      max-width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;


    }
    .step-title{
      font-size: 18px;
      font-weight: 600;
      line-height: 1.44;
      color: #ffffff;
      margin-bottom: 5px;
    }

    .step-sub-title{
      font-size: 24px;
      font-weight: 300;
      letter-spacing: -0.46px;
      color: #ffffff;
    }
  }




  .create-area{
    margin-left: 57px;
    margin-top: 30px;

    .title{
      font-size: 34px;
      color: #2d3640;
      margin-bottom: 14px;
    }
    .sub-title{
      font-size: 18px;
      line-height: 1.44;
      color: #6a7685;
      span{
        font-weight: bold;
        color: #2d3640;
      }
    }

    .main-cats-container{
      margin-top: 60px;
      display: flex;

      flex-wrap: wrap;
      .main-cats{
        margin-bottom: 30px;
      }

      .main-cats-bg{
        cursor: pointer;
        width: 215px; height: 190px;
        background-repeat: no-repeat;
        background-size: 190px 190px;

        .cat-title{
          padding-top: 106px;
          padding-left: 20px;
          padding-bottom: 5px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.18;

        }
        .cat-sub-title{
          padding-left: 20px;
          opacity: 0.4;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.43;

          span{
            font-weight: bold;
          }
        }
      }

      img{
        width: 190px;
        height: 190px;
      }
    }
  }

</style>
